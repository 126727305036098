<template>
	<div id="foodManage" v-loading="loading">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>总务</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">食材管理</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="nav-tab-bar-box u-f-item u-f-jsb">
				<div class="nav-tab-bar u-f-item">
					<div class="font_14 nav-tab-item" @click="twacherTap(index)" :class="index == gradeIndex ? 'active' : ''" v-for="(item, index) in gradeMenu" :key="index">
						{{ item.name }}
					</div>
				</div>
			</div>
			<div class="content">
				<div class="title u-f-item u-f-jsb">
					<div>共{{ total }}条数据</div>
					<div class="u-f-item"><el-button size="small" @click="openDialog(1)">出库</el-button></div>
				</div>
				<el-table :data="List" height="66vh" ref="multipleTable" tooltip-effect="dark" style="width: 100%;border: 1px solid #EEEEEE;">
					<el-table-column type="index" label="序号" width="70" align="center"></el-table-column>
					<el-table-column prop="name" label="食材名称" align="center"></el-table-column>
					<el-table-column prop="number" label="数量" align="center">
						<template slot-scope="scope">
							<span>{{scope.row.number}}{{scope.row.unit}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="price" label="单价" align="center"></el-table-column>
					<el-table-column prop="date" label="出库时间" align="center">
						<template slot-scope="scope">
							<span>{{$common.timeToDate(scope.row.date)}}</span>
						</template>
					</el-table-column>
				</el-table>
				<div class="u-f-right" style="padding: 15px 0;">
					<el-pagination
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
						:current-page.sync="page"
						:page-size="limit"
						layout="total, prev, pager, next"
						:total="total"
					></el-pagination>
				</div>
			</div>
			<!-- 添加学科 -->
			<el-dialog title="添加出库" :visible.sync="addSubject" width="500px" :before-close="handleClose">
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							食材
						</div>
						<el-select v-model="food_material_id" filterable placeholder="请选择要出库的食材" style="width: 50%;">
						    <el-option
						      v-for="item in foodList"
						      :key="item.value"
						      :label="item.name"
						      :value="item.id">
						    </el-option>
						</el-select>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							数量
						</div>
						 <el-input-number v-model="number" :min="1" label="数量"></el-input-number>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">出库时间</div>
						<el-date-picker v-model="date" type="datetime" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm" placeholder="选择日期时间"></el-date-picker>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="closeShow" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submit">确 定</el-button>
				</span>
			</el-dialog>
		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
export default {
	components: {
		index
	},
	data() {
		return {
			gradeMenu: [{ name: '食材入库' }, { name: '食材出库' }],
			gradeIndex: 1,
			List: [],
			addSubject: false,
			loading: false,
			editid: '',
			page: 1,
			limit: 10,
			total: 0,
			showTitle: '添加入库',
			food_material_id: '',
			number: '',
			price: '',
			date: '',
			showType:1,
			foodList:[]
		};
	},
	mounted() {
		this.getInfo();
		this.getFoodList()
	},
	methods: {
		getFoodList(){
			this.$api.setting
				.foodMaterialIndex({})
				.then(res => {
					if (res.data.code == 1) {
						let list = res.data.data.rows;
						this.foodList = list;
					} else {
						this.$message.error(res.data.msg);
					}
				});
		},
		closeShow() {
			this.init();
			this.addSubject = false;
		},
		openDialog(type, item) {
			this.addSubject = true;
		},
		init() {
			this.food_material_id = '';
			this.number = '';
			this.date = '';
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.page = val;
			this.getInfo();
		},
		twacherTap(index) {
			this.gradeIndex = index;
			if (index == 0) {
				this.$router.push('/foodManage');
			} else if (index == 1) {
				this.$router.push('/stockOut');
			}
		},
		stopDefault(e) {
			e.stopPropagation();
		},
		getInfo() {
			this.$api.setting
				.foodMaterialOutIndex({
					page: this.page,
					limit: this.limit
				})
				.then(res => {
					if (res.data.code == 1) {
						let list = res.data.data.rows;
						this.total = res.data.data.total;
						this.List = list;
					} else {
						this.$message.error(res.data.msg);
					}
				});
		},
		// 关闭弹窗
		handleClose(done) {
			this.init();
			done();
		},
		// 添加学科
		submit() {
			if (this.showType == 1) {
				this.addSubjects();
			} else {
				this.edit();
			}
		},
		addSubjects() {
			if (this.food_material_id == '' || !this.food_material_id) {
				return this.$message.error('请选择食材');
			}
			if (this.number == '' || !this.number) {
				return this.$message.error('请输入数量');
			}
			if (this.date == '' || !this.date) {
				return this.$message.error('请选择入库时间');
			}
			this.$api.setting
				.addFoodMaterialOut({
					food_material_id: this.food_material_id,
					number: this.number,
					date: this.date
				})
				.then(res => {
					if (res.data.code == 1) {
						this.$message.success('添加成功');
						this.init();
						this.addSubject = false;
						this.getInfo();
					} else {
						this.$message.error(res.data.msg);
					}
				});
		}
	}
};
</script>

<style lang="scss">
#foodManage {
	.nav-tab-bar-box {
		background-color: #ffffff;
		padding: 0.52vw;
		margin-top: 1.04vw;
		.nav-tab-bar {
			.nav-tab-item {
				color: #6e7a87;
				padding: 0 0.78125vw;
				border-right: 1px solid #f3f3f3;
				cursor: pointer;
				-webkit-touch-callout: none;
				-webkit-user-select: none;
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}
			&div:last-child {
				border-right: none;
			}
			.active {
				font-size: 0.833vw;
				color: #63acff;
				position: relative;
				font-weight: bold;
			}
			.active::after {
				position: absolute;
				content: '';
				height: 2px;
				left: 0.78125vw;
				right: 0.78125vw;
				background-color: #63acff;
				bottom: -0.6vw;
			}
		}
	}
	.content {
		margin-top: 10px;
		background-color: #ffffff;
		height: 80vh;
		padding: 0 25px;
		.title {
			padding: 15px 0 15px 0;
		}
		.el-table {
			table-layout: fixed;
		}
		.el-table th > .cell,
		.el-table .cell {
			overflow: hidden; /*超出部分隐藏*/
			white-space: nowrap; /*不换行*/
			text-overflow: ellipsis; /*超出部分文字以...显示*/
		}
		.el-table thead {
			background: #fafafa !important;
		}
		.el-table th {
			padding: 15px 5px !important;
			background: #fafafa !important;
		}
		.el-table::before {
			left: 0;
			bottom: 0;
			width: 100%;
			height: 0;
		}
		.el-table .btn {
			padding: 5px 10px;
			text-align: center;
			font-size: 12px;
		}
	}
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0 20px 35px 20px;
		overflow-y: auto;
		max-height: 600px;
		.edit-box {
			.input-box {
				margin-top: 35px;
				padding-left: 15px;
				.input-lebal {
					color: #aeb5b8;
					margin-right: 10px;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
				.el-icon-remove-outline {
					font-size: 1.5625vw;
					color: #d7d9db;
					margin-left: 10px;
				}
			}
			.avatar-uploader .el-upload {
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				overflow: hidden;
			}
			.avatar-uploader .el-upload:hover {
				border-color: #409eff;
			}
			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 90px;
				height: 90px;
				line-height: 90px;
				text-align: center;
			}
			.avatar {
				width: 90px;
				height: 90px;
				display: block;
			}
		}
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}
</style>
